<template>
  <div class="mb-3">
    <CRow class="my-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-end">
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
        </CCol>
    </CRow>

    <CRow class="mt-2">
        <CCol sm="12">
            <dataTableExtended
                class="align-center-row-datatable"
                :items="computedInspectionContainer"
                :fields="Inpectionsfields"
                :items-per-page="5"
                column-filter
                pagination
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :noItemsView="tableText.noItemsViewText"
                :loading="loading"
                sorter
            >
                <template #Status="{item}">
                    <td class="text-center">
                    <CBadge :color="getBadge(item.Status)">{{item.Status}}</CBadge>
                    </td>
                </template>
            </dataTableExtended>
        </CCol>
    </CRow>
  </div>
</template>
<script>
    import General from '@/_mixins/general';
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import ReportSearchContainer from '@/_mixins/reportsSearchContainer';
    import ENUM from '@/_store/enum';

    //data
    function data() {
        return {
            Items: [],
            loading: false,
            ModalBlContainer: false,
            dataInspections: [],
        };
    }

    //methods
    async function onBtnPdf(){
    }

    async function onBtnExport(valor) {
        let _lang = this.$i18n.locale;
        this.$store.state.containerSearch.loading = ENUM.LOADING;
        let tableHeader = {label:[], key:[]};
        let rowDataFormated = [];

        this.Inpectionsfields.map(item=>{   
            if (item.key!='pdf') {
                tableHeader.label.push(item.label);
                tableHeader.key.push(item.key);
            }
        })
        
        for (let i = 0; i < this.dataInspections.length; i++) {
            rowDataFormated.push({
                RowNumber: this.dataInspections[i].IdX,
                Inspections: (_lang=='en' ? this.dataInspections[i].TpInspectionNameEn : this.dataInspections[i].TpInspectionNameEs) ?? 'N/A',
                DeviceCode: this.dataInspections[i].DeviceCode ?? 'N/A',
                eventName: _lang=='en' ? this.dataInspections[i].EventNameEn : this.dataInspections[i].EventNameEs ?? 'N/A',
                FormatedDate: this.dataInspections[i].TransactionDate ? DateFormater.formatDateTimeWithSlash(this.dataInspections[i].TransactionDate) : 'N/A',
                TransaLogin: this.dataInspections[i].TransaLogin ?? 'N/A',
                Status: this.dataInspections[i].Status ?? 'N/A',
            });   
        }
        if(this.nulo!="null" && this.dataInspections.length !== 0) {
            await this.generateExcel(rowDataFormated, this.$t('label.inspections'), valor, tableHeader.label, false, tableHeader, 'SERVICIOS LOGISTICOS OCK', this.$store.state.containerSearch.dataContainer);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.$store.state.containerSearch.loading = ENUM.LOADED;
    }

    //computeds
    function computedInspectionContainer() {
        let _lang = this.$i18n.locale;
        return  this.dataInspections.map((item) => Object.assign({}, item, {
            RowNumber: item.IdX ?? 'N/A',
            Inspections: _lang=='en' ? item.TpInspectionNameEn : item.TpInspectionNameEs ?? 'N/A',
            eventName: _lang=='en' ? item.EventNameEn : item.EventNameEs ?? 'N/A',
            DeviceCode: item.DeviceCode ?? 'N/A',
            FormatedDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : 'N/A',
            TransaLogin: item.TransaLogin ?? 'N/A',
            Status: item.Status ?? 'N/A',
            YardName: item.YardName ?? '',
            _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        }));
    }

    function Inpectionsfields(){
        return [
            { key: 'RowNumber', label: '#', _style: 'width:3%; text-align:center', _classes: 'text-center', filter: false },
            { key: 'Inspections', label: this.$t('label.inspectionType'),  _classes: 'text-uppercase text-center', _style: 'width:20%; text-align:center;', sorter: true, filter: true},
            { key: 'eventName', label: this.$t('label.event'),  _classes: 'text-uppercase text-center', _style: 'width:20%; text-align:center;', sorter: true, filter: true},
            { key: 'YardName', label: this.$t('label.yard'), _style:'width:10%;', _classes: 'text-uppercase center-cell text-center', sorter: true, filter: true},
            { key: 'DeviceCode', label: this.$t('label.device'),  _style:'width:20%; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'FormatedDate', label: this.$t('label.date'),  _style:'width:20%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'TransaLogin', label: this.$t('label.user'),  _style:'width:20%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'Status', label: this.$t('label.status'),  _style:'width:20%; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
        ];
    }
    
    export default{
        name: 'inspections-list',
        data,
        mixins: [General, ReportSearchContainer],
        components: {
        },
        methods: {
            onBtnExport,
            onBtnPdf,
            onBtnPdf
        },
        computed: {
            Inpectionsfields,
            computedInspectionContainer,
            ...mapState({
                dataContainer: state => state.containerSearch.dataContainer,
            })
        },
        watch:{
            dataContainer: function (newValue) {
                if (Object.keys(newValue).length > 0 && this.$store.state.containerSearch.dataContainer?.InspectionsJson){
                    this.dataInspections = this.$store.state.containerSearch.dataContainer?.InspectionsJson;
                }
                else
                    this.dataInspections = [];
            },
        }
    }
</script>
<style lang="scss">

    .icon{
        background-color: null; 
        border: 0px;
    }
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
</style>