<template>
  <div class="mb-3">
    <CRow class="my-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-end">
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
        </CCol>
    </CRow>
    <dataTableExtended
        class="align-center-row-datatable"
        :items="computedListDispatch"
        :fields="Dispatchfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
    >

        <template #Status="{ item }">
            <td class="text-center">
                <CBadge :color="getBadge(item.Status)">
                    {{ $t('label.'+item.Status) }}
                </CBadge>
            </td>
        </template>
    
        <template #options="{ item }">
            <td class="text-center">
            
                <CButton
                    square
                    size="sm"
                    color="watch"
                    class="d-flex align-items-center"
                    @click="toggleContainerEdit(item)"
                    v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.eirTransaction'),
                    placement: 'top-end'
                    }"
                >
                    <CIcon name="eye" />
                </CButton>
            
            </td>
        </template>

        <template #pdf="{ item }">
            <td class="text-center">
                <CButton
                    square
                    size="sm"
                    color="danger"
                    class="d-flex align-items-center"
                    @click="toggleContainerEdit(item)"
                    v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.eirTransaction'),
                    placement: 'top-end'
                    }"
                >
                    <CIcon name="cil-file-pdf" />
                </CButton>
            </td>
        </template>
    </dataTableExtended>
  </div>
</template>
<script>
    import General from '@/_mixins/general';
    import { DateFormater } from '@/_helpers/funciones';
    import ReportSearchContainer from '@/_mixins/reportsSearchContainer';
    import { mapState } from 'vuex';
    import ENUM from '@/_store/enum';

    //data
    function data() {
        return {
            Items: [],
            loading: false,
            ModalBlContainer: false,
            ContainerItem:{},
            dataDispatch: [],
        };
    }


    async function onBtnExport(valor) {
        let _lang = this.$i18n.locale;
        this.$store.state.containerSearch.loading = ENUM.LOADING;
        let tableHeader = {label:[], key:[]};
        let rowDataFormated = [];

        this.Dispatchfields.map(item=>{   
            if (item.key!='options' && item.key!='pdf') {
                tableHeader.label.push(item.label);
                tableHeader.key.push(item.key);
            }
        })

        for (let i = 0; i < this.dataDispatch.length; i++) {
            rowDataFormated.push({
                RowNumber: this.dataDispatch[i].IdX ?? 'N/A',
                EirNumber: this.dataDispatch[i].EirNumber ?? 'N/A',
                ReceptionBpAct: this.dataDispatch[i].ReceptionBpAct ?? 'N/A',
                Dua: this.dataDispatch[i].Dua ?? 'N/A',
                OutPass: this.dataDispatch[i].OutPass ?? 'N/A',
                OutEirNumber: this.dataDispatch[i].OutEirNumber ?? 'N/A',
                DriverName: this.dataDispatch[i].DriverName ?? 'N/A',
                LicensePlate:this.dataDispatch[i].LicensePlate ?? 'N/A',
                CarrierName: this.dataDispatch[i].CarrierName ?? 'N/A',
                DeviceCod: this.dataDispatch[i].DeviceCod ?? 'N/A',
                TransaLogin: this.dataDispatch[i].TransaLogin ?? 'N/A',
                FormatedDate: this.dataDispatch[i].TransactionDate ? DateFormater.formatDateTimeWithSlash(this.dataDispatch[i].TransactionDate) : 'N/A',
                Status: this.dataDispatch[i].Status ?? 'N/A',
            });   
        }
        if(this.nulo!="null" && this.dataDispatch.length !== 0) {
            await this.generateExcel(rowDataFormated, this.$t('label.dispatch'), valor, tableHeader.label, false, tableHeader, 'SERVICIOS LOGISTICOS OCK', this.$store.state.containerSearch.dataContainer);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.$store.state.containerSearch.loading = ENUM.LOADED;
    }

    function computedListDispatch() {
        return  this.dataDispatch.map((item) => Object.assign({}, item, {
            RowNumber: item.IdX ?? 'N/A',
            EirNumber: item.EirNumber ?? 'N/A',
            ReceptionBpAct: item.ReceptionBpAct ?? 'N/A',
            Dua: item.Dua ?? 'N/A',
            OutPass: item.OutPass ?? 'N/A',
            OutEirNumber: item.OutEirNumber ?? 'N/A',
            DriverName: item.DriverName ?? 'N/A',
            LicensePlate:item.LicensePlate ?? 'N/A',
            CarrierName: item.CarrierName ?? 'N/A',
            DeviceCod: item.DeviceCod ?? 'N/A',
            TransaLogin: item.TransaLogin ?? 'N/A',
            FormatedDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : 'N/A',
            YardName: item.YardName ?? '',
            Status: item.Status ?? 'N/A',
            _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        }));
    }

    function Update() {
        this.ModalBlContainer = false;
    }


    function Dispatchfields(){
        return [
            { key: 'RowNumber', label: '#', _style: 'min-width:50px; text-align:center', _classes: 'text-center', filter: false },
            { label: this.$t('label.nTicketOut'), key: 'OutEirNumber', _classes: 'text-uppercase text-center', _style: 'min-width:175px; text-align:center;', sorter: true, filter: true},
            { key: 'EirNumber', label: this.$t('label.nroEirBolipuertos'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px; text-align:center;', sorter: true, filter: true},
            { key: 'YardName', label: this.$t('label.yard'), _style:'min-width:150px; text-align:center;', _classes: 'text-uppercase center-cell text-center', sorter: true, filter: true},
            { key: 'TpTransacEirAcronym', label: this.$t('label.transaction'), _style:'min-width:180px; text-align:center;', _classes: 'text-uppercase center-cell text-center', sorter: true, filter: true},
            { key: 'ReceptionBpAct', label: this.$t('label.receptionDocument'), _style:'min-width:220px; text-align:center;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
            { key: 'OutPass', label: this.$t('label.ticketOut'), _style:'min-width:145px', _classes:"center-cell text-center", sorter: true, filter: true},
            { key: 'Dua', label: this.$t('label.nDua'),  _style:'min-width:145px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'CarrierName', label: this.$t('label.transportationCarrier'),  _style:'min-width:145px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'DriverName', label: this.$t('label.driver'),  _style:'min-width:145px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'LicensePlate', label: this.$t('label.vehicle'),  _style:'min-width:145px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'DeviceCod', label: this.$t('label.device'),  _style:'min-width:145px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'TransaLogin', label: this.$t('label.user'),  _style:'min-width:145px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'FormatedDate', label: this.$t('label.date'),  _style:'min-width:145px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'Status', label: this.$t('label.status'),  _style:'min-width:145px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
        ];
    }

    
    function toggleContainerEdit(item) {
        this.ContainerItem = item;
        this.ModalBlContainer = !this.ModalBlContainer;
    }

    export default{
        name: 'index-dispatch',
        data,
        mixins: [General, ReportSearchContainer],
        methods: {
            Update,
            toggleContainerEdit,
            onBtnExport
        },
        computed: {
            Dispatchfields,
            computedListDispatch,
            ...mapState({
                dataContainer: state => state.containerSearch.dataContainer,
            })
        },
        watch:{
            dataContainer: function (newValue) {
                if (Object.keys(newValue).length > 0 && this.$store.state.containerSearch.dataContainer?.MovementsInYardJson && this.$store.state.containerSearch.dataContainer?.MovementsInYardJson[0]?.DispatchJson){
                    this.dataDispatch = this.$store.state.containerSearch.dataContainer?.MovementsInYardJson[0].DispatchJson;
                }
                else
                    this.dataDispatch = [];
            },
        }
    }
</script>
<style lang="scss">

    .icon{
        background-color: null; 
        border: 0px;
    }
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
</style>